<template>
    <div ref="rotation" class="rotation">
        <div class="timeRotation">
            <div class="table_box">
                <div class="table_row">
                    <div class="one_item item_every title_box">
                        <div style="opacity: 0;">占位</div>
                        <div class="date_text">日期</div>
                        <div class="driver_text">司机</div>
                        <div class="line"></div>
                    </div>
                    <div class="item_every" v-for="(item,k) in showList" :key="k">
                        <div class="month">
                            <div class="btn_box" v-if="type=='1'">
                                <el-button type="primary" size="mini" @click="changDate(item.month,1)">上一年</el-button>
                                <el-button type="primary" size="mini" @click="changDate(item.month,2)">上一月</el-button>
                            </div>
                            {{item.month}}
                            <div class="btn_box" v-if="type=='1'">
                                <el-button type="primary" size="mini" @click="changDate(item.month,3)">下一月</el-button>
                                <el-button type="primary" size="mini" @click="changDate(item.month,4)">下一年</el-button>
                            </div>
                        </div>
                        <div class="onerow_item_box">
                            <div class="onerow_item every_width" :class="dateKey(one)==hoverItem.col?'hover':''" :style="itemWidth?'width:'+itemWidth+'px':''" v-for="(one,kk) in item.children" :key="kk">
                                <div>{{one.wed}}</div>
                                <div>{{one.day}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table_row" v-for="(item,k) in driverList" :key="k">
                    <div class="one_item item_every">{{item.driverNa}}</div>
                    <div class="item_every every_width" :class="k==hoverItem.row||kk==hoverItem.col?'hover':''" :style="itemWidth?'width:'+itemWidth+'px':''" v-for="(one,kk) in dateList" :key="kk" @click.stop="clickItem({...item,date:one.date})" @mouseenter="onmouseover({row:k,col:kk})" @mouseleave="onmouseleave()" style="cursor:pointer;">
                        <div :style="k==hoverItem.row||kk==hoverItem.col?'background-color: #dd7700;':'background-color: #ff7600;'" v-for="(val,kkk) in item.date" :key="kkk">
                            <div v-if="val.date == one.date" @click.stop="clickItem(val)">{{val.typeName}}</div>
                        </div>
                    </div>
                </div>
                <div class="entry_box" v-if="!driverList.length">
                    请先进行轮休设置
                </div>
            </div>
            <div class="flex_box">
                <div class="table_row">
                    <div class="one_item item_every title_box">
                        <div style="opacity: 0;">占位</div>
                        <div class="date_text">日期</div>
                        <div class="driver_text">司机</div>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="table_row" v-for="(one,k) in driverList" :key="k">
                    <div class="one_item item_every" :class="k==hoverItem.row?'hover':''">{{one.driverNa}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        driverList: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    watch:{
    },
    data() {
        return {
            dateList:[],
            showList:[],
            itemWidth:'',

            beginDate:'',
            endDate:'',
            type:'0',

            hoverItem:{},
        }
    },
    methods:{
        init(option){
            this.beginDate = option.beginDate
            this.endDate = option.endDate
            this.type = option.type?option.type:'0'
            this.getDateList()
        },
        getDateList(){
            let list = this.$util.getBetweenDateList(this.beginDate,this.endDate)
            if(!list.length){
                return false
            }
            this.dateList = list
            let newList = []
            list.forEach(item=>{
                let month = item.date.split('-').slice(0,2).join('-')
                let newItem = newList.filter(one=>one.month == month).length?newList.filter(one=>one.month == month)[0]:''
                if(newItem){
                    newItem.children.push(item)
                }else{
                    newList.push({
                        month,
                        children:[item]
                    })
                }
            })
            this.showList = newList
            if(this.type == '1'){
                this.setWidth()
            }
        },
        setWidth(){
            let rotation = this.$refs.rotation
            let w = rotation.offsetWidth - 82
            this.itemWidth = w/this.dateList.length
            console.log(rotation.offsetWidth)
            console.log(this.itemWidth)
        },
        changDate(ym,type){
            let y = Number(ym.split('-')[0])
            let m = Number(ym.split('-')[1])-1
            switch(type) {
                case 1:
                    y = y-1
                    break
                case 2:
                    if(m == 0){
                        y = y-1
                        m = 11
                    }else{
                        m = m-1
                    }
                    break
                case 3:
                    if(m == 11){
                        y = y+1
                        m = 0
                    }else{
                        m = m+1
                    }
                    break
                case 4:
                    y = y+1
                    break
            }
            this.beginDate = new Date(y,m,1).Format('yyyy-MM-dd')
            this.endDate = new Date(y,m+1,0).Format('yyyy-MM-dd')
            this.$emit('changeDate',{
                beginDate:this.beginDate,
                endDate:this.endDate,
            })
            this.getDateList()
        },
        clickItem(item){
            console.log(item)
            this.$emit('clickItem',item)
        },
        dateKey(item){
            return this.dateList.findIndex(one=>one.date == item.date)
        },
        onmouseover(item){
            this.hoverItem = item
        },
        onmouseleave(){
            this.hoverItem = {}
        },
    },
}
</script>
<style lang="scss" scoped="rotation">
.rotation{
    .timeRotation{
        font-size: 12px;
        position:relative;
        border: 1px solid #EBEEF5;
        display: inline-grid;
        .table_box{
            overflow: auto;
            max-width: 100%;
            display: inline-grid;
        }
        .table_row{
            display: inline-flex;
            flex-wrap:nowrap;
            text-align: center;
            .title_box{
                position: relative;
                overflow: hidden;
                height: 100%;
                .date_text{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                }
                .driver_text{
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                }
                .line{
                    position: absolute;
                    transform: rotate(38deg);
                    height: 1px;
                    width: 165px;
                    background-color: #EBEEF5;
                    top: 40px;
                    left: -30px;
                }
            }
            .month{
                display: flex;
                align-items: center;
                justify-content:center;
                height: 30px;
                font-size: 18px;
                font-weight: bold;
                border-bottom: 1px solid #EBEEF5;
                .el-button{
                    padding: 3px 10px;
                }
                .btn_box:first-of-type{
                    margin-right: 20px;
                }
                .btn_box:last-of-type{
                    margin-left: 20px;
                }
            }
            .onerow_item_box{
                display: inline-flex;
                .onerow_item{
                    position:relative;
                    div{
                        height: 17px;
                        +div{
                            border-top: 1px solid #EBEEF5;
                        }
                    }
                    +.onerow_item:before{
                        content:'';
                        position: absolute;
                        width: 1px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        background-color: #EBEEF5;
                    }
                }
            }
            +.table_row {
                border-top: 1px solid #EBEEF5;
            }
        }
        .one_item{
            width: 80px;
        }
        .every_width{
            width: 30px;
        }
        .item_every{
            position:relative;
            line-height: 20px;
            +.item_every:before{
                content:'';
                position: absolute;
                width: 1px;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: #EBEEF5;
            }
        }
        .flex_box {
            position: absolute;
            background-color: #fff;
            left: 0;
            top: 0;
            .table_row{
                display: block;
            }
            .table_row:first-of-type{
                height: 64px;
            }
        }
        .entry_box{
            color: #DCDFE6;
            padding: 40px;
            text-align: center;
            font-size: 22px;
            border-top: 1px solid #EBEEF5;
        }
    }
    .hover{
        background-color: #dddddd;
    }
}
</style>