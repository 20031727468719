<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'">
            <div style="text-align: center;">
                <time-rotation ref="timeRotation" :driverList="driverList"></time-rotation>
            </div>
            <div style="color:red;font-size: 12px;">备注：标注“休”、“上午”或“下午”表示该司机该时间不参与排班计划</div>
        </jy-dialog>
    </div>
</template>
<script>
import timeRotation from '@/components/pages/admin/common/timeTable/timeRotation'
export default {
    data() {
        return {
            dialogVisible: false,
            title:'轮休情况',
            beginDate:'',
            endDate:'',
            driverList:[],
        }
    },
    computed: {
    },
    components: {
        timeRotation,
    },
    created() {
    },
    watch: {
        dialogVisible(val){
            if(!val){
                this.driverList = []
            }
        },
    },
    methods: {
        init(option) {
            this.beginDate = option.beginDate
            this.endDate = option.endDate
            this.driverList = option.driverList
            this.dialogVisible = true
            this.$nextTick(()=>{
                this.$refs.timeRotation.init({
                    beginDate:this.beginDate,
                    endDate:this.endDate,
                })
            })
        },
    }
}
</script>