<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'">
            <div v-if="step==0">
                <el-form :model="formData" ref="dialogForm" label-width="120px" :rules="rules">
                    <el-form-item label="线路" prop="routeId">
                        <el-select v-model="formData.routeId">
                            <el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="日时刻表名称" prop="dayTimeTId">
                        <el-select v-model="formData.dayTimeTId">
                            <el-option v-for="one in timetableList" :key="one.dayTimeTId" :label="one.cname" :value="one.dayTimeTId"></el-option>
                        </el-select>
                        <el-button class="btnr_right" type="primary" @click="checkTimeTable">查看时刻表</el-button>
                    </el-form-item>
                    <el-form-item label="日期" prop="date">
                        <el-date-picker v-model="formData.date" :picker-options="expireTimeOption" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="车辆" prop="carNames">
                        <el-input type="textarea" autosize v-model="formData.carNames" readonly @focus="chooseCar"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="司机" prop="carNames">
                        <el-input type="textarea" autosize v-model="formData.drivers" disabled></el-input>
                    </el-form-item> -->
                    <el-form-item label="" prop="rotation">
                        <span style="color:red;">备注：轮休表中安排休息的时间不参与排班计划</span>
                        <el-button class="btnr_right" type="primary" @click="rotationTable">查看轮休表</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div v-else>
                <time-calendar ref="timeCalendar" :showbtn="false" @choose="choose"></time-calendar>
                <time-table :list="dayTimeTList" :canUpdate="false"></time-table>
            </div>
            <template #footer>
                <div class="footer_box">
                    <el-button type="primary" v-if="step==1" @click="step=0">上一步</el-button>
                    <el-button @click="cancel">取消</el-button>
                    <el-button type="primary" v-if="step==0" @click="next">排班预览</el-button>
                    <el-button type="primary" v-if="step==1" @click="save">完成</el-button>
                </div>
            </template>
        </jy-dialog>
        <car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
        <rotation-table ref="rotationTable"></rotation-table>
        <day-time-table ref="dayTimeTable"></day-time-table>
    </div>
</template>
<script>
import carSelectbatch from '@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch'
import dayTimeTable from './dayTimeTable'
import rotationTable from './rotationTable'
import timeTable from '@/components/pages/admin/common/timeTable/timeTable'
import timeCalendar from '@/components/pages/admin/common/timeTable/timeCalendar'
export default {
    data() {
        return {
            dialogVisible: false,
            title:'新增排班计划',
            step:0,
            formData: {
                routeId: '',
                dayTimeTId:'',
                date:[],
                carNames:'',
                drivers:'',
            },
            carList:[],
            routeList: [],
            timetableList: [],
            dayTimeTable:[],
            driverList:[],
            driverRotationList:[],
            rules: {
                routeId: [
                    {
                        required: true,
                        message: "请选择线路",
                        trigger: "change",
                    },
                ],
                dayTimeTId: [
                    {
                        required: true,
                        message: "请选择日时刻表",
                        trigger: "change",
                    },
                ],
                date: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
                carNames: [
                    {
                        required: true,
                        message: "请选择车辆",
                        trigger: "change",
                    },
                ],
            },
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },

            dateTotalList:[],
            dayTimeTList:[],
        }
    },
    computed: {
    },
    components: {
        carSelectbatch,
        dayTimeTable,
        rotationTable,
        timeTable,
        timeCalendar,
    },
    created() {
        this.getRouteList()
    },
    watch: {
        dialogVisible(val){
            if(!val){
                this.resetForm()
            }
        },
        'formData.routeId'(val){
            this.formData.dayTimeTId = ''
            this.formData.carNames = ''
            this.formData.drivers = ''
            this.timetableList = []
            this.carList = []
            this.driverList = []
            if(val){
                this.getTimetableList()
            }
        },
        'formData.dayTimeTId'(val){
            this.dayTimeTable = []
            if(val){
                this.getdayTimeList()
            }
        },
    },
    methods: {
        init() {
            this.dialogVisible = true
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        getTimetableList(){
            let url = '/day/timet/queryPage'
            let option = {
                routeId:this.formData.routeId,
            }
            this.$http.post(url,option).then(({detail})=>{
                this.timetableList = detail.list
            })
        },
        //获取日时刻表
        getdayTimeList(){
            let url = '/day/timet/queryDetail'
            let option = {
                dayTimeTId:this.formData.dayTimeTId,
            }
            this.$http.post(url,option).then(res=>{
                this.dayTimeTable = res.detail
            })
        },
        checkTimeTable(){
            if(!this.formData.dayTimeTId){
                this.$message({
                    type: "warning",
                    message: '请先选择时刻表',
                });
                return false
            }
            this.$refs.dayTimeTable.init(this.dayTimeTable)
        },
        resetForm() {
            this.step = 0
            this.timetableList = []
            this.carList = []
            this.dateTotalList = []
            this.dayTimeTList = []
            this.dayTimeTable = []
            this.driverRotationList = []
            this.driverList = []

            this.formData = {
                routeId: '',
                dayTimeTId:'',
                date:[],
                carNames:'',
                drivers:'',
            }
            this.$nextTick(()=>{
                this.$refs.dialogForm.clearValidate()
            })
        },
        cancel() {
            this.dialogVisible = false;
            console.log("cancel");
        },
        chooseCar(){
            if(!this.formData.routeId){
                this.$message({
                    type: "warning",
                    message: '请先选择线路',
                });
                return false
            }
            this.$refs.carSelectbatch.init(this.carList,this.formData.routeId)
        },
        chooseCarList(list){
            let routeObj = this.routeList.filter(item=>item.routeId == this.formData.routeId)[0]
            this.carList = list
            let driverList = []
            this.carList.forEach(item=>{
                item.vehicleNo = item.plateNum
                item.routeId = routeObj.routeId
                item.routeNa = routeObj.cName
                item.orgId = routeObj.orgId
                item.orgPath = routeObj.orgPath
                if(item.driverInfoList){
                    item.driverInfoList.forEach(one=>{
                        one.vehicleNo = item.plateNum
                        one.vehicleId = item.vehicleId
                        if(!driverList.some(val=>val.driverId == one.driverId&&val.vehicleId == one.vehicleId)){
                            driverList.push({
                                ...one,
                            })
                        }
                    })
                }
            })
            this.driverList = driverList
            this.formData.carNames = list.map(item=>item.plateNum).join(',')
            this.formData.drivers = list.map(item=>item.driverNames).join(',')
        },
        async getRotationList(){
            let option = {
                routeId:this.formData.routeId,
                startTime:this.formData.date[0],
                endTime:this.formData.date[1],
            }
            let url = '/rotation/plan/queryList'
            await this.$http.post(url, option).then(({ detail }) => {
                console.log(detail)
                let driverList = []
                detail.forEach(item=>{
                    if(item.rotationPlanF == '0'){
                        item.typeName = '休'
                        item.section = [Number('00:00'.split(':').join('')),Number('23:59'.split(':').join(''))]
                    }else if(item.rotationPlanF == '1'){
                        item.typeName = '上午'
                        item.section = [Number('00:00'.split(':').join('')),Number(item.limitT.split(':').join(''))]
                    }else{
                        item.typeName = '下午'
                        item.section = [Number(item.limitT.split(':').join('')),Number('23:59'.split(':').join(''))]
                    }
                    if(!driverList.some(one=>one.driverId == item.driverId)){
                        driverList.push(
                            {
                                driverId:item.driverId,
                                driverNa:item.driverNa,
                                date:[
                                    {
                                        date:item.rotationPlanT,
                                        id:item.id,
                                        typeName:item.typeName,
                                        rotationPlanF:item.rotationPlanF,
                                        limitT:item.limitT,
                                        section:item.section,
                                    }
                                ]
                            }
                        )
                    }else{
                        let obj = driverList.filter(one=>one.driverId == item.driverId)[0]
                        obj.date.push(
                            {
                                date:item.rotationPlanT,
                                id:item.id,
                                typeName:item.typeName,
                                rotationPlanF:item.rotationPlanF,
                                limitT:item.limitT,
                                section:item.section,
                            }
                        )
                    }
                })
                let newList = []
                let routeObj = this.routeList.filter(item=>item.routeId == this.formData.routeId)[0]
                this.driverList.forEach(item=>{
                    driverList.forEach(one=>{
                        if(one.driverId == item.driverId){
                            newList.push(
                                {
                                    driverId:item.driverId,
                                    driverNa:item.cName,
                                    workNum:item.wNum,
                                    vehicleNo:item.vehicleNo,
                                    vehicleId:item.vehicleId,
                                    date:one.date,

                                    routeId:routeObj.routeId,
                                    routeNa:routeObj.cName,
                                    orgId:routeObj.orgId,
                                    orgPath:routeObj.orgPath,
                                }
                            )
                        }
                    })
                    if(!driverList.some(one=>one.driverId == item.driverId)){
                        newList.push(
                            {
                                driverId:item.driverId,
                                driverNa:item.cName,
                                workNum:item.wNum,
                                vehicleNo:item.vehicleNo,
                                vehicleId:item.vehicleId,
                                date:[],

                                routeId:routeObj.routeId,
                                routeNa:routeObj.cName,
                                orgId:routeObj.orgId,
                                orgPath:routeObj.orgPath,
                            }
                        )
                    }
                })
                this.driverRotationList = newList
                console.log(this.driverRotationList)
            })
        },
        rotationTable(){
            this.$refs.dialogForm.validate(async valid => {
                if (valid) {
                    await this.getRotationList()
                    let newList = []
                    this.driverRotationList.forEach(item=>{
                        if(!newList.some(one=>one.driverId == item.driverId)){
                            newList.push(item)
                        }
                    })
                    let option = {
                        beginDate:this.formData.date[0],
                        endDate:this.formData.date[1],
                        driverList:newList,
                    }
                    this.$refs.rotationTable.init(option)
                } else {
                    return false
                }
            })
        },
        next(){
            this.$refs.dialogForm.validate(async valid => {
                if (valid) {
                    await this.getRotationList()
                    let defaultDate = this.$util.getBetweenDateList(this.formData.date[0],this.formData.date[1])[0].date
                    this.step = 1
                    this.$nextTick(()=>{
                        this.$refs.timeCalendar.init(this.formData.date[0],this.formData.date[1],defaultDate)
                    })
                    this.getDayList()
                } else {
                return false
                }
            })
        },
        getDayList(){
                let dayTimeTList = this.$util.deepCopy(this.dayTimeTable) //日时刻表
                dayTimeTList.sort((a,b)=>{
                    return a.dTime.replace(':','') - b.dTime.replace(':','')
                })
                // console.log(dayTimeTList)
                let dateList = this.$util.getBetweenDateList(this.formData.date[0],this.formData.date[1]) //日期列表
                this.carList.forEach(one=>{
                    one.ischoose = false
                    one.driverInfoList&&one.driverInfoList.forEach(val=>{
                        val.ischoose = false
                    })
                })
                let checkItem = (dlist,date,time)=>{
                    let newTime = Number(time.split(':').join(''))
                    let k = dlist.findIndex(item=>item.ischoose)
                    if(k == dlist.length-1 || k == -1){
                        k = 0
                    }else{
                        k++
                    }
                    dlist.forEach(one=>{
                        one.ischoose = false
                    })
                    let item = dlist[k]
                    item.ischoose = true
                    let chooseDriver = {}
                    // console.log(item)
                    if(item.driverInfoList){
                        let workDriverList = item.driverInfoList.filter(one=>{
                            let driverObj = this.driverRotationList.filter(val=>val.driverId == one.driverId&&val.vehicleId == one.vehicleId)[0]
                            return !driverObj.date.some(d=>d.date == date&&newTime<d.section[1]&&newTime>d.section[0])
                        })
                        if(workDriverList.length){
                            let index = item.driverInfoList.findIndex(one=>one.ischoose)
                            item.driverInfoList.forEach(one=>one.ischoose = false)
                            if(index == item.driverInfoList.length-1 || index == -1){
                                workDriverList[0].ischoose = true
                                chooseDriver = this.driverRotationList.filter(val=>val.driverId == workDriverList[0].driverId&&val.vehicleId == workDriverList[0].vehicleId)[0]
                            }else{
                                let newDriverList = item.driverInfoList.slice(index+1)
                                let find = false
                                for (var i=0;i<newDriverList.length;i++){
                                    workDriverList.forEach(val=>{
                                        if(newDriverList[i].driverId == val.driverId){
                                            find = true
                                            val.ischoose = true
                                            chooseDriver = this.driverRotationList.filter(one=>one.driverId == val.driverId&&val.vehicleId == one.vehicleId)[0]
                                        }
                                    })
                                    if(find){
                                        break;
                                    }
                                }
                                if(!find){
                                    workDriverList[0].ischoose = true
                                    chooseDriver = this.driverRotationList.filter(val=>val.driverId == workDriverList[0].driverId&&val.vehicleId == workDriverList[0].vehicleId)[0]
                                }
                            }
                        }
                    }
                    return {
                        ...item,
                        ...chooseDriver,
                    }
                }
                dateList.forEach(item=>{
                    let newList = this.$util.deepCopy(dayTimeTList)
                    newList.forEach((one,kk)=>{
                        let dItem = checkItem(this.carList,item.date,one.dTime)
                        newList[kk] = {
                            ...one,
                            ...dItem,
                            isActive:'1',
                        }
                    })
                    item.children = newList
                })
                console.log(dateList)
                this.dateTotalList = dateList
                this.dayTimeTList = dateList[0].children
        },

        save(){
            let url = '/waybillPlan/batchAdd'
            let allList = []
            let list = this.$util.deepCopy(this.dateTotalList)
            list.forEach(item=>{
                item.children.forEach(one=>{
                    one.oDate = item.date
                    delete one.createT
                    delete one.updateT
                })
                allList = allList.concat(item.children)
            })
            let option = allList
            console.log(option)
            this.$http.post(url,option,{isRequestParam:false}).then(()=>{
                this.$message({
                    type: "success",
                    message: '新增计划班次成功',
                });
                this.$emit('updateList')
                this.dialogVisible = false;
            })
        },

        choose(e){
            this.dateTotalList.forEach(item=>{
                if(item.date == e.date){
                    this.dayTimeTList = item.children
                }
            })
        },
    }
}

</script>
<style lang="scss" scoped="addPlanShift">
.btnr_right{
    float: right;
}
.swidth{
    width: 100px;
}
.timewidth{
    width: 150px;
}
.footer_box{
    text-align: center;
}
</style>
