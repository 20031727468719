<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth" appendToBody>
            <div>
                <el-form :model="diaform" ref="diaform" label-width="120px" :rules="rules">
                    <el-form-item label="线路" prop="routeId">
                        <el-select v-model="diaform.routeId" disabled>
                            <el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="营运状态" prop="operationT">
                        <el-radio-group v-model="diaform.operationT">
                            <el-radio :label="'0'">营运</el-radio>
                            <el-radio :label="'1'">非营运</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="日期" prop="oDate">
                        <el-date-picker
                            :disabled="type == 'update'"
                            :picker-options="expireTimeOption"
                            v-model="diaform.oDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="起始站" prop="sSiteId" v-if="diaform.operationT=='0'">
                        <el-select v-model="diaform.sSiteId">
                            <el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="终点站" prop="eSiteId" v-if="diaform.operationT=='0'">
                        <el-select v-model="diaform.eSiteId">
                            <el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="方向" prop="schemeT" v-if="diaform.operationT=='0'">
                        <el-select v-model="diaform.schemeT">
                            <el-option v-for="one in schemeList" v-bind:key="one.value" :label="one.label" :value="one.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="发车时间" prop="dTime">
                        <el-time-picker v-model="diaform.dTime" format="HH:mm" value-format="HH:mm"></el-time-picker>
                    </el-form-item>
                    <el-form-item label="跨线路" prop="isCrossL" v-if="diaform.operationT=='0'">
                        <el-radio-group v-model="diaform.isCrossL">
                            <el-radio label="1">否</el-radio>
                            <el-radio label="2">是</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="车辆所属线路" prop="cRouteId" v-if="diaform.isCrossL == '2'">
                        <el-select v-model="diaform.cRouteId">
                            <el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="车辆" prop="vehicleId">
                        <el-input v-model="diaform.vehicleNo" readonly @focus="chooseCar"></el-input>
                    </el-form-item>
                    <el-form-item label="司机" prop="driverId">
                        <el-select v-model="diaform.driverId">
                            <el-option v-for="one in driverInfoList" v-bind:key="one.driverId" :label="one.cName" :value="one.driverId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="班次类型" prop="type" v-if="diaform.operationT=='0'">
                        <el-select v-model="diaform.type">
                            <el-option v-for="one in typeList" v-bind:key="one.value" :label="one.label" :value="one.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="业务类型" prop="businessT" v-if="diaform.operationT=='1'">
                        <el-select v-model="diaform.businessT" placeholder="请选择" >
                            <!-- <el-option v-for="item in dreportTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option> -->
                            <el-option label="包车" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始位置" prop="sAddr" v-if="diaform.operationT=='1'">
                        <el-row>
                            <el-col :span="18">
                                <el-input v-model="diaform.sAddr" ></el-input>
                            </el-col>
                            <el-col :span="4" :offset="1">
                                <el-button type="primary" plain v-on:click="selectStartMap">选择位置</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="结束位置" prop="eAddr" v-if="diaform.operationT=='1'">
                        <el-row>
                            <el-col :span="18">
                                <el-input v-model="diaform.eAddr" ></el-input>
                            </el-col>
                            <el-col :span="4" :offset="1">
                                <el-button type="primary" plain v-on:click="selectEndMap">选择位置</el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
        <car-select ref="carSelect" @selected="choose"></car-select>
        <map-select ref="mapSelect" @getAddress="getAddress" @getLngLat="getLngLat"></map-select>
    </div>
</template>
<script>
    import carSelect from "@/components/pages/admin/resourcesAllocation/carDriver/carSelect";
    import { getShiftType } from "@/common/js/wordbook.js";
    import mapSelect from '@/components/pages/admin/basicInformation/siteManagement/mapSelect.vue'
    export default {
        props: {
            appendToBody: {
                type: Boolean,
                default: false
            }
        },
        data() {
            let typeList = getShiftType();
            return {
                // 对话框类型
                type: "",
                title: "",

                diaform: {
                    routeId: "",
                    routeNa: "",
                    orgId: "",
                    orgPath: "",

                    oDate: "",
                    sSiteId: "",
                    eSiteId: "",
                    schemeT: "",
                    dTime: "",
                    isCrossL: "1",
                    cRouteId: "",
                    vehicleId: "",
                    vehicleNo: "",
                    customNo: "",
                    driverNa: "",
                    driverId: "",
                    workNum: "",
                    remark: "",
                    type: "",
                    operationT: '0',
                    businessT:'',
                    sAddr: '',
                    sLng: '',
                    sLat: '',
                    eAddr: '',
                    eLng: '',
                    eLat: ''
                },
                rType: "",
                dialogVisible: false,

                routeList: [],
                siteList: [],
                driverInfoList: [],
                schemeList: [],
                typeList,
                rules: {
                    oDate: [
                        {
                            required: true,
                            message: "请选择日期",
                            trigger: "change"
                        }
                    ],
                    sSiteId: [
                        {
                            required: true,
                            message: "请选择起始站",
                            trigger: "change"
                        }
                    ],
                    eSiteId: [
                        {
                            required: true,
                            message: "请选择终点站",
                            trigger: "change"
                        }
                    ],
                    schemeT: [
                        {
                            required: true,
                            message: "请选择方向",
                            trigger: "change"
                        }
                    ],
                    dTime: [
                        {
                            required: true,
                            message: "请选择发车时间",
                            trigger: "change"
                        }
                    ],
                    isCrossL: [
                        {
                            required: true,
                            message: "请选择是否跨线路",
                            trigger: "change"
                        }
                    ],
                    cRouteId: [
                        {
                            required: true,
                            message: "请选择所属线路",
                            trigger: "change"
                        }
                    ],
                    vehicleId: [
                        {
                            required: true,
                            message: "请选择车辆",
                            trigger: "change"
                        }
                    ],
                    driverId: [
                        {
                            required: true,
                            message: "请选择司机",
                            trigger: "change"
                        }
                    ],
                    businessT: [
                        {
                            required: true,
                            message: "请选择业务类型",
                            trigger: "change"
                        }
                    ],
                    sAddr: [
                        {
                            required: true,
                            message: "请选择开始位置",
                            trigger: "change"
                        }
                    ],
                    eAddr: [
                        {
                            required: true,
                            message: "请选择结束位置",
                            trigger: "change"
                        }
                    ]
                },
                expireTimeOption: {
                    disabledDate(date) {
                        return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                    }
                }
            };
        },
        created() {
            this.getRouteList();
        },
        watch: {
            dialogVisible(newview) {
                if (!newview) {
                    this.resetForm("diaform");
                }
            },
            "diaform.isCrossL"() {
                this.diaform.cRouteId = "";
                this.diaform.vehicleId = "";
                this.diaform.vehicleNo = "";
                this.diaform.customNo = "";
                this.diaform.driverNa = "";
                this.diaform.driverId = "";
                this.diaform.workNum = "";
                this.driverInfoList = [];
            },
            "diaform.routeId"(val) {
                if (val) {
                    this.getRtype();
                } else {
                    this.rType = "";
                }
            },
            rType: function (val) {
                if (val) {
                    if (val == "0") {
                        this.schemeList = [
                            {
                                value: "1",
                                label: "上行"
                            },
                            {
                                value: "2",
                                label: "下行"
                            }
                        ];
                    } else if (val == "1") {
                        this.schemeList = [
                            {
                                value: "3",
                                label: "环形"
                            }
                        ];
                    }
                } else {
                    this.schemeList = [];
                }
                if (!this.schemeList.some(one => one.value == this.diaform.schemeT)) {
                    this.diaform.schemeT = "";
                }
            }
        },
        components: {
            carSelect,
            mapSelect
        },
        methods: {
            getRtype() {
                this.routeList.forEach(item => {
                    if (item.routeId == this.diaform.routeId) {
                        this.rType = item.rType;
                    }
                });
            },
            getSiteList() {
                let url = "/site/info/queryByRouteId";
                let params = {
                    routeId: this.diaform.routeId
                };
                this.$http.post(url, params).then(({ detail }) => {
                    let list = detail.map(item => {
                        return {
                            ...item,
                            ...item.routeSite,
                            ...item.siteInfo
                        };
                    });
                    this.siteList = list;
                });
            },
            getRouteList() {
                let url = "/route/info/queryRouteInfoByOrgIds";
                return this.$http.post(url).then(({ detail }) => {
                    this.routeList = detail;
                });
            },
            // 打开对话框
            init(option, type) {
                this.dialogVisible = true;
                this.type = type || "add";
                console.log(this.type);
                if (this.type == "update") {
                    this.title = "编辑";
                } else {
                    this.title = "新增";
                }
                this.$nextTick(() => {
                    this.diaform = {
                        ...this.diaform,
                        ...option
                    };
                    this.$nextTick(() => {
                        this.diaform = {
                            ...this.diaform,
                            ...option
                        };
                        console.log(this.diaform);
                        this.getSiteList();
                        if (this.type == "update") {
                            this.getDriverList();
                        }
                    });
                });
            },
            // 重置
            resetForm(formName) {
                this.diaform = {
                    routeId: "",
                    routeNa: "",
                    orgId: "",
                    orgPath: "",

                    oDate: "",
                    sSiteId: "",
                    eSiteId: "",
                    schemeT: "",
                    dTime: "",
                    isCrossL: "1",
                    cRouteId: "",
                    vehicleId: "",
                    vehicleNo: "",
                    customNo: "",
                    driverNa: "",
                    driverId: "",
                    workNum: "",
                    remark: "",
                    type: "",
                    operationT: '0',
                    businessT:'',
                    sAddr: '',
                    sLng: '',
                    sLat: '',
                    eAddr: '',
                    eLng: '',
                    eLat: ''
                };
                this.$refs[formName].resetFields();
                this.driverInfoList = [];
            },

            cancel() {
                this.dialogVisible = false;
                console.log("cancel");
            },
            save() {
                this.$refs.diaform.validate(valid => {
                    if (valid) {
                        let url = "";
                        if (this.type == "add") {
                            url = "/waybillPlan/add";
                        } else {
                            url = "/waybillPlan/update";
                        }
                        let option = JSON.parse(JSON.stringify(this.diaform));
                        let driverObj = this.driverInfoList.filter(item => item.driverId == option.driverId)[0];
                        option.driverNa = driverObj.cName;
                        option.workNum = driverObj.wNum;
                        option.isActive = "1";
                        if(option.operationT == '1') {
                            option.schemeT = ''
                            option.type = ''
                            option.isCrossL = ''
                        } else {
                            option.sSiteNa = this.siteList.filter(item => item.siteId == option.sSiteId)[0].cName;
                            option.eSiteNa = this.siteList.filter(item => item.siteId == option.eSiteId)[0].cName;
                        }
                        this.$http.post(url, option).then(res => {
                            console.log(res);
                            this.$message({
                                message: res.msg,
                                type: "success"
                            });
                            this.dialogVisible = false;
                            if (this.type == "add") {
                                this.$emit("updateList", res.detail.schemeId);
                            } else {
                                this.$emit("updateList");
                            }
                        });
                    } else {
                        return false;
                    }
                });
            },
            chooseCar() {
                this.$refs.carSelect.init(this.diaform.cRouteId || this.diaform.routeId);
            },
            choose(e) {
                this.diaform.vehicleId = e.vehicleId;
                this.diaform.vehicleNo = e.plateNum;
                this.diaform.customNo = e.customNo;
                this.driverInfoList = e.driverInfos || [];
            },
            getDriverList() {
                let url = "/base/vehicle/queryPage";
                let option = {
                    pageIndex: 1,
                    pageSize: 10,
                    orgId: this.diaform.cRouteId || this.diaform.routeId,
                    plateNum: this.diaform.vehicleNo
                };
                this.$http.post(url, option).then(({ detail }) => {
                    this.driverInfoList = detail.list[0].driverInfos;
                });
            },
            selectStartMap() {
                if (this.diaform.sLng && this.diaform.sLat) {
                    let newLngLat = this.$util.wgs84tobd09(Number(this.diaform.sLng),Number(this.diaform.sLat))
                    var option = {
                        lng: newLngLat[0],
                        lat: newLngLat[1],
                    }
                    this.$refs.mapSelect.init(option,1)
                }else{
                    this.$refs.mapSelect.init("",1)
                }
            },
            selectEndMap() {
                if (this.diaform.eLng && this.diaform.eLat) {
                    let newLngLat = this.$util.wgs84tobd09(Number(this.diaform.eLng),Number(this.diaform.eLat))
                    var option = {
                        lng: newLngLat[0],
                        lat: newLngLat[1],
                    }
                    this.$refs.mapSelect.init(option,2)
                }else{
                    this.$refs.mapSelect.init("",2)
                }
            },
            getLngLat(lng, lat,type) {
                let newLngLat = this.$util.bd09towgs84(lng*1,lat*1)
                if(type == 1) {
                    this.diaform = {
                        ...this.diaform,
                        sLng:newLngLat[0],
                        sLat:newLngLat[1],
                    }
                    this.$nextTick(()=>{
                        this.$refs.diaform.validateField(['sLng','sLat'])
                    })
                } else {
                    this.diaform = {
                        ...this.diaform,
                        eLng:newLngLat[0],
                        eLat:newLngLat[1],
                    }
                    this.$nextTick(()=>{
                        this.$refs.diaform.validateField(['eLng','eLat'])
                    })
                }
            },
            getAddress(add,type) {
                if(type == 1) {
                    this.diaform = {
                        ...this.diaform,
                        sAddr: add
                    }
                    this.$nextTick(()=>{
                        this.$refs.diaform.validateField(['sAddr'])
                    })
                } else {
                    this.diaform = {
                        ...this.diaform,
                        eAddr: add
                    }
                    this.$nextTick(()=>{
                        this.$refs.diaform.validateField(['eAddr'])
                    })
                }
            }
        }
    };
</script>
<style lang="scss" scoped></style>
