<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'">
            <time-table :canUpdate="false" :list="dayTimeTList"></time-table>
        </jy-dialog>
    </div>
</template>
<script>
import timeTable from '@/components/pages/admin/common/timeTable/timeTable'
export default {
    data() {
        return {
            dialogVisible: false,
            title:'日时刻表',
            dayTimeTList:[],
        }
    },
    computed: {
    },
    components: {
        timeTable,
    },
    created() {
    },
    watch: {
        dialogVisible(val){
            if(!val){
                this.dayTimeTList = []
            }
        },
    },
    methods: {
        init(dayTimeTList) {
            this.dayTimeTList = dayTimeTList
            this.dialogVisible = true
        },
    }
}
</script>