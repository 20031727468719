<template>
    <div class="car_select">
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth" append-to-body>
            <jy-query ref="form" :model="form">
                <jy-query-item prop="plateNum" label="车牌号:" :span="1.5">
                    <el-input placeholder="请输入" v-model="form.plateNum"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('form')">重置</el-button>
                </template>
            </jy-query>
            <jy-table :data="dataList" v-loading="loading" @row-click="rowClick">
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column prop="plateNum" label="车牌号"></jy-table-column>
                <jy-table-column prop="vin" label="vin"></jy-table-column>
                <jy-table-column prop="driverNames" label="已关联司机"></jy-table-column>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
            <div slot="footer" class="dialog-footer"></div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            form: {
                plateNum: ''
            },
            routeId:'',
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            loading: false,
            dataList: [],
            dialogVisible: false,
            title: '选择车辆'
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('form')
            }
        }
    },
    activated() {
    },
    methods: {
        init(routeId) {
            this.dialogVisible = true
            this.routeId = routeId
            this.getList()
        },
        getList() {
            let option = {
                ...this.form,
                orgId:this.routeId,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            Object.keys(option).forEach(key=>{
                if (option[key]===undefined) {
                    delete option[key]
                }
            })
            let url = '/base/vehicle/queryPage'
            this.$http.post(url, option).then(
                ({ detail }) => {
                    let list = detail.list.map(item=>{
                        item.driverNames = item.driverInfos?item.driverInfos.map(item=>item.cName).join(','):''
                        return {
                            ...item,
                            ...item.sysOrg,
                            ...item.vehicleInfo,
                        }
                    })
                    this.dataList = list
                    this.total = detail.total
                    this.loading = false
                },
                () => {
                    this.loading = false
                }
            )
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
        },
        // 重置
        resetForm(formName) {
            // this.routeId = ''
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        rowClick(row) {
            console.log(row)
            this.$emit('selected', row)
            this.dialogVisible = false
        }
    }
}

</script>
