<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_table" :width="global.dialogTabelWidth" append-to-body>
            <jy-query ref="form" :model="form">
                <jy-query-item prop="plateNum" label="车牌号:" :span="1.5">
                    <el-input placeholder="请输入" v-model="form.plateNum"></el-input>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" @click="oncheck">查询</el-button>
                    <el-button type="primary" @click="resetForm('form')">重置</el-button>
                </template>
            </jy-query>
            <div class="choose_box">
                <div class="left_box">已选车辆:</div>
                <div class="chooseCar_box">
                    <el-tag style="margin-right:10px;" v-for="tag in batchChooseList" :key="tag.vehicleId" closable @close="closeChoose(tag.vehicleId)">
                        {{tag.plateNum}}
                    </el-tag>
                </div>
            </div>
            <jy-table ref="dtable" :data="dataList" v-loading="loading" @select-all="selectchange" @select="selectchange">
                <jy-table-column type="index" label="序号" width="60"></jy-table-column>
                <jy-table-column type="selection" width="40"></jy-table-column>
                <jy-table-column prop="plateNum" label="车牌号"></jy-table-column>
                <jy-table-column prop="vin" label="vin"></jy-table-column>
                <jy-table-column prop="driverNames" label="已关联司机"></jy-table-column>
            </jy-table>
            <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
            <div slot="footer" class="dialog-footer">
                <div>
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="selectSure">确 定</el-button>
                </div>
            </div>
        </jy-dialog>
    </div>
</template>
<script type="text/javascript">
export default {
    data() {
        return {
            routeId:'',
            form: {
                plateNum: ''
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            loading: false,
            dataList: [],
            batchChooseList: [],
            dialogVisible: false,
            title: '选择车辆'
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                // this.resetForm('form')
                this.routeId = ''
                this.batchChooseList = []
            }
        }
    },
    activated() {
    },
    methods: {
        init(list,routeId) {
            this.routeId = routeId || ''
            this.batchChooseList = list || []
            this.dialogVisible = true
            this.getList()
        },
        getList() {
            let option = {
                orgId:this.routeId,
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            console.log(option)
            let url = '/base/vehicle/queryPage'
            this.$http.post(url, option).then(({ detail }) => {
                let list = detail.list.map(item=>{
                    item.driverInfoList = item.driverInfos
                    item.driverNames = item.driverInfos?item.driverInfos.map(item=>item.cName).join(','):''
                    return {
                        ...item,
                        ...item.sysOrg,
                        ...item.vehicleInfo,
                    }
                })
                this.dataList = list
                this.total = detail.total
                this.loading = false
                this.$nextTick(()=>{
                    let chooseList = this.dataList.filter(one=>this.batchChooseList.some(item=>item.vehicleId == one.vehicleId))
                    chooseList.forEach(item=>{
                        this.$refs.dtable.toggleRowSelection(item,true)
                    })
                })
            },() => {
                this.loading = false
            }
            )
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        // 勾选数据
        selectchange(selection) {
            console.log(selection)
            let newList = []
            this.batchChooseList.forEach(item=>{
                if(!this.dataList.some(one=>one.vehicleId == item.vehicleId) || selection.some(one=>one.vehicleId == item.vehicleId)){
                    newList.push(item)
                }
            })
            selection.forEach(item=>{
                if(!newList.some(one=>one.vehicleId == item.vehicleId)){
                    newList.push(item)
                }
            })
            this.batchChooseList = newList
        },
        closeChoose(id){
            console.log(id)
            this.batchChooseList = this.batchChooseList.filter(item=> item.vehicleId != id)
            let list =  this.dataList.filter(one=>one.vehicleId == id)
            if(list.length){
                this.$refs.dtable.toggleRowSelection(list[0],false)
            }
        },
        // 确定选择
        selectSure() {
            if (this.batchChooseList.length === 0) {
                this.$message({
                    message: '请选择车辆',
                    type: 'warning'
                })
            } else {
                this.dialogVisible = false
                this.$emit('choose', this.batchChooseList)
            }
        }
    }
}
</script>
<style lang="scss" scoped="carSelectBatch">
.choose_box{
    .left_box{
        width: 90px;
        text-align: right;
        float: left;
        font-size: 14px;
        padding-right: 10px;
    }
    .chooseCar_box{
        margin-left: 90px;
        height: 100px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        padding: 10px;
    }
}
</style>
