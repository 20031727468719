<template>
    <div id="timeCalendar" ref="timeCalendar">
        <div :style="'width:'+item.width+'px'" class="month_item" v-for="(item,k) in showList" :key="k">
            <div class="item_title">
                <div class="btn_box" v-if="showbtn">
                    <el-button type="primary" size="mini" @click="changDate(item.month,1)">上一年</el-button>
                    <el-button type="primary" size="mini" @click="changDate(item.month,2)">上一月</el-button>
                </div>
                <div class="month_text">
                    {{item.month}}
                </div>
                <div class="btn_box" v-if="showbtn">
                    <el-button type="primary" size="mini" @click="changDate(item.month,3)">下一月</el-button>
                    <el-button type="primary" size="mini" @click="changDate(item.month,4)">下一年</el-button>
                </div>
            </div>
            <div class="item_date">
                <div class="day_wed" :class="one.isCheck?'isCheck':''" :style="'width:'+itemWidth+'px'" v-for="(one,kk) in item.children" :key="kk" @click="choose(one)">
                    <div>{{one.wed}}</div>
                    <div>{{one.day}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import elementResizeDetectorMaker from "element-resize-detector"
export default {
    props:{
        showbtn:{
            type: Boolean,
            default:true,
        },
    },
    watch:{
    },
    data() {
        return {
            list:[],
            showList:[],
            itemWidth:'',
            bDate:'',
            eDate:'',
            chooseDate:'',

            erd:null,
        }
    },
    mounted () {
        this.erd = elementResizeDetectorMaker()
        this.erd.listenTo(document.getElementById("timeCalendar"), ()=> {
            this.list.length&&this.setWidth(this.list.length)
        })
    },
    beforeDestroyed (){
        if(this.erd){
            this.erd.uninstall(document.getElementById("timeCalendar"))
            this.erd = null
        }
    },

    methods:{
        init(beginDate,endDate,chooseDate){
            this.bDate = beginDate
            this.eDate = endDate
            this.chooseDate = chooseDate
            this.getDateList()
        },
        getDateList(){
            let list = this.$util.getBetweenDateList(this.bDate,this.eDate)
            if(!list.length){
                return false
            }
            this.list = list
            let newList = []
            list.forEach(item=>{
                let month = item.date.split('-').slice(0,2).join('-')
                let newItem = newList.filter(one=>one.month == month).length?newList.filter(one=>one.month == month)[0]:''
                if(newItem){
                    newItem.children.push(item)
                }else{
                    newList.push({
                        month,
                        children:[item]
                    })
                }
            })
            this.showList = newList
            this.$nextTick(()=>{
                this.list.forEach(item=>{
                    if(item.date == this.chooseDate){
                        item.isCheck = true
                    }
                })
                this.setWidth(list.length)
            })
        },
        setWidth(length){
            let timeCalendar = this.$refs.timeCalendar
            let w = timeCalendar.offsetWidth
            this.itemWidth = w/length
            this.showList.forEach(item=>{
                item.width = item.children.length*this.itemWidth
            })
        },
        choose(one){
            this.list.forEach(item=>{
                if(item.date == one.date){
                    item.isCheck = true
                }else{
                    item.isCheck = false
                }
            })
            this.chooseDate = one.date
            this.$emit('choose',one)
        },
        changDate(ym,type){
            let y = Number(ym.split('-')[0])
            let m = Number(ym.split('-')[1])-1
            switch(type) {
                case 1:
                    y = y-1
                    break
                case 2:
                    if(m == 0){
                        y = y-1
                        m = 11
                    }else{
                        m = m-1
                    }
                    break
                case 3:
                    if(m == 11){
                        y = y+1
                        m = 0
                    }else{
                        m = m+1
                    }
                    break
                case 4:
                    y = y+1
                    break
            }
            this.bDate = new Date(y,m,1).Format('yyyy-MM-dd')
            this.eDate = new Date(y,m+1,0).Format('yyyy-MM-dd')
            this.getDateList()
            this.$emit('changeMonth',{
                beginDate: this.bDate,
                endDate: this.eDate,
            })
        },
    },
}
</script>
<style lang="scss" scoped="timeCalendar">
#timeCalendar{
    width: 100%;
    font-size: 12px;
    display: flex;
    .month_item{
        border: 1px solid #EBEEF5;
        .item_title{
            border-bottom: 1px solid #EBEEF5;
            height: 30px;
            display: flex;
            justify-content:center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            .el-button{
                padding: 3px 10px;
            }
            .btn_box:first-of-type{
                margin-right: 20px;
            }
            .btn_box:last-of-type{
                margin-left: 20px;
            }
        }
        .item_date{
            display: flex;
            text-align: center;
            .day_wed{
                line-height: 22px;
                &.isCheck{
                    color: #fff;
                    background-color: #4077bc;
                }
                div{
                    +div{
                        border-top: 1px solid #EBEEF5;
                    }
                }
                + .day_wed{
                    border-left: 1px solid #EBEEF5;
                }
            }
        }
    }
}
</style>